import { Loader as GoogleMapsJsApiLoader } from "@googlemaps/js-api-loader";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import DeleteIcon from "@/assets/icons/delete-circle.svg";
import SearchIcon from "@/assets/icons/search.svg";

import styles from "./GoogleSearch.module.scss";

/**
 * https://developers.google.com/maps/documentation/javascript/place-autocomplete
 */
const componentRestrictions = {
  country: ["us", "ca", "uk"],
};

const fields = ["address_components", "formatted_address", "geometry", "types"];

const types = [
  "street_number",
  "route",
  "locality",
  "administrative_area_level_1",
  "postal_code",
];

const GoogleSearch = ({ clearSearchInput, onInputSelect, resetTimestamp }) => {
  const hasSelected = useRef(false);
  const inputRef = useRef(null);
  const isLoaded = useRef(false);
  const [place, setPlace] = useState("");

  /**
   *
   */
  const clearInput = () => {
    if (inputRef.current?.value === "") {
      return;
    }

    clearSearchInput();

    inputRef.current.value = "";

    setPlace("");
  };

  /**
   *
   */
  const triggerDownArrow = () => {
    const downArrowEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      key: "ArrowDown",
      keyCode: 40,
      which: 40,
    });

    inputRef.current?.dispatchEvent(downArrowEvent);

    const timeoutId = setTimeout(() => {
      const enterEvent = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        key: "Enter",
        keyCode: 13,
        which: 13,
      });

      inputRef.current?.dispatchEvent(enterEvent);

      hasSelected.current = false;

      clearTimeout(timeoutId);
    }, 100);
  };

  /**
   * Get the first place based on input given on enter key press
   */
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && !hasSelected.current) {
      triggerDownArrow();
      hasSelected.current = true;
    }
  };

  /**
   *
   */
  useEffect(() => {
    if (isLoaded.current) {
      return;
    }

    isLoaded.current = true;

    let googleApiRef;
    let autocomplete;

    const loader = new GoogleMapsJsApiLoader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
      version: "weekly",
    });

    loader
      .load()
      .then(async (google) => {
        googleApiRef = google;

        /**
         * Good to know:
         * By default, when a user selects a place, SearchBox returns all of the available data fields
         * for the selected place, and you will be billed accordingly. There is no way to constrain
         * SearchBox requests to only return specific fields. To keep from requesting (and paying for)
         * data that you don't need, use the Autocomplete widget instead.
         */

        autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
          bounds: new google.maps.LatLngBounds(),
          componentRestrictions,
          fields,
          types,
        });

        autocomplete.addListener("place_changed", () => {
          setPlace(autocomplete?.getPlace());
        });
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      document.querySelector(".pac-container")?.remove?.();

      if (googleApiRef) {
        googleApiRef.maps?.event?.clearInstanceListeners?.(autocomplete);
      }
    };
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (place?.types?.[0] === "administrative_area_level_1") {
      onInputSelect(
        null,
        null,
        {
          region: place.address_components.slice(-2)[0].long_name,
          subRegion: "",
        },
        inputRef.current?.value || "",
        place
      );
    } else if (place?.types?.[0] === "colloquial_area") {
      onInputSelect(
        null,
        null,
        {
          region: place.address_components.slice(-2)[0].long_name,
          subRegion: place.address_components.slice(-3)[0].long_name,
        },
        inputRef.current?.value || "",
        place
      );
    } else if (place?.geometry?.location) {
      const lat = place.geometry.location.lat();
      const long = place.geometry.location.lng();

      onInputSelect(
        lat,
        long,
        undefined,
        inputRef.current?.value || "",
        place.formatted_address
      );
    } else {
      console.info("No place information.");
    }
  }, [place, onInputSelect]);

  /**
   *
   */
  useEffect(() => {
    inputRef.current.value = "";
    setPlace("");
  }, [resetTimestamp]);

  return (
    <div className={styles.search}>
      <input
        aria-label="Search Text"
        autoComplete="off"
        className="location-search-input"
        name="autoSearchString"
        onKeyDown={handleEnterKeyPress}
        placeholder="Search For A Location"
        ref={inputRef}
        type="text"
      />
      <SearchIcon className={styles.searchIcon} />
      <DeleteIcon className={styles.deleteIcon} onClick={clearInput} />
    </div>
  );
};

GoogleSearch.propTypes = {
  clearSearchInput: PropTypes.func.isRequired,
  onInputSelect: PropTypes.func.isRequired,
  resetTimestamp: PropTypes.number,
};

export default GoogleSearch;
