import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { SESSION_STORAGE_KEYS } from "@/constants";
import { CorpEmailNonEligibleBanner } from "@/features/join-flow";
import { AppointmentSelector } from "@/features/user-flow";

import AppointmentActions from "./AppointmentActions";
import AppointmentClubLabel from "./AppointmentClubLabel";
import styles from "./ScheduleAppointment.module.scss";

const ScheduleAppointment = ({
  appointment,
  appointments = [],
  appointmentType = "Appointment",
  clubName,
  isEditing = false,
  isReferral = false,
  onClubEdit,
  onSchedule,
  onSkip,
  showCorpLeadIneligibility = false,
  showGuidedWorkouts = false,
}) => {
  const [selectedAppointment, setSelectedAppointment] = useState(appointment);
  const [noOnlineJoinMessage, setNoOnlineJoinMessage] = useState("");

  const handleSchedule = () => {
    onSchedule(selectedAppointment);
  };

  useEffect(() => {
    if (sessionStorage.getItem(SESSION_STORAGE_KEYS.NO_ONLINE_JOIN_MESSAGE)) {
      setNoOnlineJoinMessage(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.NO_ONLINE_JOIN_MESSAGE)
      );

      sessionStorage.removeItem(SESSION_STORAGE_KEYS.NO_ONLINE_JOIN_MESSAGE);
    } else {
      setNoOnlineJoinMessage("");
    }
  }, []);

  useEffect(() => {
    setSelectedAppointment(appointment);
  }, [appointments, appointment]);

  return (
    <div className={styles.scheduleAppointment}>
      {noOnlineJoinMessage && (
        <p className={styles.noOnlineJoinMessage}>{noOnlineJoinMessage}</p>
      )}
      {showCorpLeadIneligibility && <CorpEmailNonEligibleBanner />}

      <AppointmentClubLabel
        canEdit={!isEditing && !isReferral}
        clubName={clubName}
        onClick={() => {
          setNoOnlineJoinMessage("");
          onClubEdit();
        }}
        showGuidedWorkouts={showGuidedWorkouts}
      />

      {appointments?.length > 0 && (
        <AppointmentSelector
          appointments={appointments}
          onSelection={setSelectedAppointment}
          selectedAppointment={selectedAppointment}
        />
      )}

      <AppointmentActions
        canSchedule={!!selectedAppointment}
        canSkip={!isEditing}
        ctaText={`${isEditing ? "Update" : "Schedule a"} ${appointmentType}`}
        onSchedule={handleSchedule}
        onSkip={onSkip}
      />
    </div>
  );
};

ScheduleAppointment.propTypes = {
  appointment: PropTypes.string,
  appointmentType: PropTypes.string,
  appointments: PropTypes.array,
  clubName: PropTypes.string,
  isEditing: PropTypes.bool,
  isReferral: PropTypes.bool,
  onClubEdit: PropTypes.func,
  onSchedule: PropTypes.func,
  onSkip: PropTypes.func,
  showCorpLeadIneligibility: PropTypes.bool,
  showGuidedWorkouts: PropTypes.bool,
};

export default ScheduleAppointment;
