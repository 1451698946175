import PropTypes from "prop-types";
import React from "react";

import { MESSAGES } from "@/constants";

const ContentfulImage = ({
  alt,
  className,
  isLazy = true,
  onLoad,
  sizes = [],
  src,
  title = MESSAGES.TAGLINE,
}) => {
  let qualityValue = 0;
  let widthValue = 1000;

  const contentfulParameters = Object.entries(
    sizes?.[0]?.params || { w: widthValue }
  )
    .map(([key, value]) => {
      // capture quality value
      if (key === "q") {
        qualityValue = value;

        return "";
      }

      // capture width value
      if (key === "w") {
        widthValue = value;

        return "";
      }

      return `${key}=${value}`;
    })
    .filter((param) => !!param)
    .join("&");

  return (
    <img
      alt={alt}
      className={className}
      data-is="ContentfulImage"
      draggable="false"
      loading={isLazy ? "lazy" : "eager"}
      onLoad={onLoad}
      src={`${src}?fm=jpg&fl=progressive&w=${widthValue}&q=${qualityValue > 0 ? qualityValue : 50}&${contentfulParameters}`}
      srcSet={[1, 2, 3]
        .map(
          (density) =>
            `${src}?fm=webp&w=${widthValue * density}&q=${qualityValue > 0 ? qualityValue : 70 - 10 * density}&${contentfulParameters} ${density}x`
        )
        .join(", ")}
      title={title}
    />
  );
};

ContentfulImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  isLazy: PropTypes.string,
  onLoad: PropTypes.func,
  sizes: PropTypes.array,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ContentfulImage;
